import React, { Component } from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "./index.css";
import { DatePicker, Typography, Switch } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Text } = Typography;

class DateViewer extends Component {
  state = {
    start: moment().startOf("day"),
    end: moment().endOf("week"),
    showTime: false,
    showTimeProps: {}
  };

  handleChange = dates => {
    if (dates && dates.length) {
      let start = dates[0];
      let end = dates[1];
      if (!this.state.showTime) {
        start = dates[0].startOf("day");
        end = dates[1].endOf("day");
      }
      this.setState({
        start,
        end
      });
    } else {
      this.setState({
        start: null,
        end: null
      });
    }
  };

  enableTime = checked => {
    if (checked) {
      this.setState({
        showTime: true,
        showTimeProps: {
          showTime: {
            use12Hours: true
          }
        }
      });
    } else {
      this.setState({ showTime: false, showTimeProps: {} });
    }
  };

  render() {
    const { start, end, showTimeProps = {} } = this.state;
    return (
      <div>
        <Switch onChange={this.enableTime} />
        show time
        <br />
        <RangePicker
          onChange={this.handleChange}
          defaultValue={[start, end]}
          {...showTimeProps}
        />
        <div>
          start:
          {start && (
            <div>
              <Text copyable>{start.startOf("second").toISOString()}</Text>{" "}
              <br />
              <Text copyable>({start.valueOf()})</Text>
            </div>
          )}
        </div>
        <div style={{ marginTop: 5 }}>
          end:
          {end && (
            <div>
              <Text copyable>{end && end.toISOString()}</Text> <br />
              <Text copyable>({end && end.valueOf()})</Text>
            </div>
          )}
        </div>
      </div>
    );
  }
}

ReactDOM.render(
  <div>
    <DateViewer />
  </div>,
  document.getElementById("container")
);
